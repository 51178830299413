
import Component from "vue-class-component";
import Vue from "vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import SelectManagersMultiple from "@/components/select/managersMultiple/SelectManagersMultiple.vue";
import SelectProjectsMultiple from "@/components/select/projectsMultiply/SelectProjectsMultiple.vue";
import SelectEstimationFilter from "@/components/select/estimation/SelectEstimationFilter.vue";
import SelectCallsFilter from "@/components/select/calls/SelectCallsFilter.vue";
import SelectLinkedPhonesMultiple from "@/components/select/linkedPhonesMultiple/SelectLinkedPhonesMultiple.vue";
import DateFilter from "@/components/lib/dateFilter/DateFilter.vue";
import DurationFilter from "@/components/lib/duration/DurationFilter.vue";
import {
  BaseEntityModel,
  CallEstimationType,
  CallsListFilterModel,
  DateFilterModel,
} from "@/services/types";
import * as utils from "@/services/utils";
import { StoreActionTypes } from "@/store/types";
import { refactorNamesList } from "@/store/modules/calls/helpers";
import { uniqBy } from "lodash";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { getStore } from "@/store/getStore";
import CallsListSttFilterSelect from "@/components/select/CallsListSttFilterSelect.vue";
import DownloadCallExcelReportButton from "@/features/calls-excel-report/DownloadCallExcelReportButton.vue";
import SelectMultiple from "@/components/select/selectMultiple/SelectMultiple.vue";

@Component({
  components: {
    SelectMultiple,
    DownloadCallExcelReportButton,
    CallsListSttFilterSelect,
    FormInput,
    ActionButton,
    SelectManagersMultiple,
    SelectProjectsMultiple,
    SelectEstimationFilter,
    SelectLinkedPhonesMultiple,
    DateFilter,
    SelectCallsFilter,
    DurationFilter,
    ModalComponent,
  },
  watch: {
    "$store.getters.getCallsLinkedPhonesFilterValue":
      "handleCallsLinkedPhonesFilterValueChange",
    selectedFilterItem: "setSavedFilters",
  },
})
export default class GridFilter extends Vue {
  private componentClass = "GridFilter";

  private modalShow = false;
  private filterName = "";

  private selectedFilterItem = null;

  private searchInputValue = "";
  private estimationSelectValue = "null";
  private initialCallsValue = 0;

  private sttFilterValue = 0

  private managersSelectedItems: BaseEntityModel[] = [];

  private projectsSelectedItems: BaseEntityModel[] = [];
  checklistsSelected: BaseEntityModel[] = [];

  private linkedPhonesSelectedItems: string[] = [];

  private callDates: DateFilterModel = {
    from: null,
    to: null,
  };

  private estimationDates: DateFilterModel = {
    from: null,
    to: null,
  };

  private managersList: BaseEntityModel[] = [];

  private minDuration = "";
  private maxDuration = "";

  async mounted() {
    this.searchInputValue = (
      this.$store.getters.getCallsFilterValue as CallsListFilterModel
    ).search;

    await this.$store.dispatch(StoreActionTypes.SET_CALLS_PROJECTS_LIST);
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LINKED_PHONES_LIST);

    this.setInitialSelectedProject();
    this.setInitialSelectedLinkedPhones();

    await this.updateManagersList();
  }

  async updateManagersList() {
    const selectedIds = this.managersSelectedItems.map((i) => i.id);
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_MANAGERS_LIST);
    this.managersList = uniqBy(
      getStore().getters.getCallsManagersList.flatMap((i) => i.managers),
      "id"
    ).sort((a, b) => a.name.localeCompare(b.name));

    this.managersSelectedItems = this.managersList.filter((m) =>
      selectedIds.includes(m.id)
    );
  }

  get getOutLineStatus() {
    const filtersValue = this.$store.getters
      .getCallsFilterValue as CallsListFilterModel;
    if (
      !filtersValue ||
      !filtersValue.search ||
      !filtersValue.projects ||
      !filtersValue.managers
    ) {
      return false;
    }
    return (
      filtersValue.search.length == 0 &&
      filtersValue.projects.length > 0 &&
      filtersValue.managers.length > 0
    );
  }

  get savedFilters() {
    return this.$store.getters.getSavedFilters.calls
      ? this.$store.getters.getSavedFilters.calls
      : [];
  }

  get modalOkBtnDisabled() {
    return this.filterName.length === 0;
  }

  selectFilterItem(item) {
    this.selectedFilterItem = item;
  }

  filterNameChange(name) {
    this.filterName = name;
  }

  modalSwitch() {
    this.modalShow = !this.modalShow;
  }

  openConfirm(name) {
    this.$store.dispatch(StoreActionTypes.SET_CONFIRM_MODAL, {
      title: `Удалить ${name}?`,
      action: () => this.removeFilterItem(name),
    });
  }

  get savedFiltersAsArray() {
    return Object.entries(this.savedFilters);
  }

  async setSavedFilters(filters) {
    if (!filters || !filters.duration) {
      return;
    }

    this.searchInputValue = filters.search;
    this.managersSelectedItems = filters.managers;
    this.projectsSelectedItems = filters.projects;
    this.linkedPhonesSelectedItems = filters.linkedCalls;
    this.estimationSelectValue = filters.isEstimated;
    this.estimationDates = filters.estimated;
    this.initialCallsValue = filters.initialCalls;
    this.callDates = filters.started;
    this.maxDuration = filters.duration.max
      ? filters.duration.max.toString()
      : "null";
    this.minDuration = filters.duration.min
      ? filters.duration.min.toString()
      : "null";

    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_LIST_FILTER,
      this.getCallsListFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }

  handleSearchInputChangeEvent(text: string) {
    this.searchInputValue = text;
  }

  handleManagersSelectEvent(selectedItems: BaseEntityModel[]) {
    this.managersSelectedItems = selectedItems;
  }

  async handleProjectsSelectEvent(selectedProjects: BaseEntityModel[]) {
    this.projectsSelectedItems = selectedProjects;

    this.managersList = [];
    this.managersSelectedItems = [];

    let newManagersList: BaseEntityModel[] = [];

    if (selectedProjects && selectedProjects.length > 0) {
      const selectedProjectsIds = selectedProjects.map((p) => p.id);
      newManagersList = getStore()
        .getters.getCallsManagersList.filter((r) =>
          selectedProjectsIds.includes(r.projectId)
        )
        .flatMap((i) => i.managers);
    } else {
      newManagersList = getStore().getters.getCallsManagersList.flatMap(
        (i) => i.managers
      );
    }

    newManagersList = uniqBy(newManagersList, "id"); // dedupe managers
    this.managersList = await refactorNamesList(newManagersList);
  }

  handleChecklistsSelectEvent(selectedItems: BaseEntityModel[]) {
    this.checklistsSelected = selectedItems;
  }

  get projectsSelectedItemsIds() {
    return this.projectsSelectedItems.map((v) => v.id);
  }

  handleEstimationSelectChangeEvent(selected: string) {
    this.estimationSelectValue = selected;
  }

  handleInitialCallsChangeEvent(selected: number) {
    this.initialCallsValue = selected;
  }

  handleLinkedPhonesSelectEvent(selectedItems: string[]) {
    this.linkedPhonesSelectedItems = selectedItems;
  }

  getStatusSelectValue(): boolean | null {
    if (
      this.estimationSelectValue == "null" ||
      this.estimationSelectValue == "ALL"
    ) {
      return null;
    } else {
      return (
        (this.estimationSelectValue as CallEstimationType) == "withEstimation"
      );
    }
  }

  getSttFilterValue(): boolean | null {
    switch (this.sttFilterValue) {
      case 1:
        return true
      case 2:
        return false
      default:
        return null
    }
  }

  getCallsListFilterModel(): CallsListFilterModel {
    return {
      search:
        this.searchInputValue && this.searchInputValue.length > 0
          ? this.searchInputValue
          : null,
      projects: this.projectsSelectedItems
        ? this.projectsSelectedItems.map((item) => item.id)
        : null,
      checklists: this.checklistsSelected.length ? this.checklistsSelected.map(i => i.id) : [],
      managers: this.managersSelectedItems
        ? this.managersSelectedItems.map((item) => item.id)
        : null,
      isEstimated: this.getStatusSelectValue(),
      linkedCalls: this.linkedPhonesSelectedItems,
      initialCalls: this.initialCallsValue,
      duration: {
        min:
          this.minDuration.length > 0 &&
          utils.getSecondsFromMmSsString(this.minDuration)
            ? utils.getSecondsFromMmSsString(this.minDuration)
            : null,
        max:
          this.maxDuration.length > 0 &&
          utils.getSecondsFromMmSsString(this.maxDuration)
            ? utils.getSecondsFromMmSsString(this.maxDuration)
            : null,
      },
      started: {
        from:
          this.callDates.from && this.callDates.from.length > 0
            ? this.callDates.from
            : null,
        to:
          this.callDates.to && this.callDates.to.length > 0
            ? this.callDates.to
            : null,
      },
      estimated: {
        from:
          this.estimationDates.from && this.estimationDates.from.length > 0
            ? this.estimationDates.from
            : null,
        to:
          this.estimationDates.to && this.estimationDates.to.length > 0
            ? this.estimationDates.to
            : null,
      },
      sttState: this.getSttFilterValue()
    };
  }

  getCallsListOriginFilterModel() {
    return {
      search:
        this.searchInputValue && this.searchInputValue.length > 0
          ? this.searchInputValue
          : null,
      projects: this.projectsSelectedItems ? this.projectsSelectedItems : null,
      managers: this.managersSelectedItems ? this.managersSelectedItems : null,
      isEstimated: this.estimationSelectValue,
      linkedCalls: this.linkedPhonesSelectedItems,
      initialCalls: this.initialCallsValue,
      duration: {
        min: this.minDuration !== "null" ? this.minDuration : null,
        max: this.maxDuration !== "null" ? this.maxDuration : null,
      },
      started: {
        from:
          this.callDates.from && this.callDates.from.length > 0
            ? this.callDates.from
            : null,
        to:
          this.callDates.to && this.callDates.to.length > 0
            ? this.callDates.to
            : null,
      },
      estimated: {
        from:
          this.estimationDates.from && this.estimationDates.from.length > 0
            ? this.estimationDates.from
            : null,
        to:
          this.estimationDates.to && this.estimationDates.to.length > 0
            ? this.estimationDates.to
            : null,
      },
    };
  }

  getCallsListDefaultFilterModel(): CallsListFilterModel {
    return {
      search: "",
      projects: [],
      managers: [],
      isEstimated: null,
      linkedCalls: [],
      initialCalls: 0,
      duration: {
        min: null,
        max: null,
      },
      started: {
        from: null,
        to: null,
      },
      estimated: {
        from: null,
        to: null,
      },
      sttState: null
    };
  }

  async okBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_LIST_FILTER,
      this.getCallsListFilterModel()
    );
    await this.updateManagersList();
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
  }

  async cancelBtnListener() {
    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_LIST_FILTER,
      this.getCallsListDefaultFilterModel()
    );
    await this.$store.dispatch(StoreActionTypes.SET_CALLS_LIST);
    this.searchInputValue = (
      this.$store.getters.getCategoriesFilterValue as CallsListFilterModel
    ).search;
    this.projectsSelectedItems = [];
    this.managersSelectedItems = [];
    this.estimationSelectValue = "null";
    this.linkedPhonesSelectedItems = [];
    this.initialCallsValue = 0;
    this.callDates = {
      from: null,
      to: null,
    };
    this.minDuration = "";
    this.maxDuration = "";
    this.estimationDates = {
      from: null,
      to: null,
    };
    this.checklistsSelected = [];
  }

  async saveExistingFilter(name) {
    this.filterName = name;
    await this.saveFiltersBtnListener();
  }

  async saveFiltersBtnListener() {
    const model = {
      name: this.filterName,
      ...this.getCallsListOriginFilterModel(),
    };
    const calls = JSON.parse(JSON.stringify(this.savedFilters));

    const exists = calls.findIndex((v) => v.name === this.filterName);
    if (exists !== -1) {
      calls[exists] = model;
    } else {
      calls.push(model);
    }

    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, {
      calls,
    });
    this.modalShow = false;

    this.selectFilterItem(model);
    this.filterName = "";
  }

  async clearSavedFiltersBtnListener() {
    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, {
      calls: [],
    });
    this.cancelBtnListener();
  }

  async removeFilterItem(name) {
    const calls = this.$store.getters.getSavedFilters.calls;

    const index = calls.findIndex((v) => v.name === name);

    calls.splice(index, 1);
    await this.$store.dispatch(StoreActionTypes.SET_SAVED_FILTERS, {
      calls,
    });
    this.cancelBtnListener();
  }

  get showSetDefaultFiltersValueBtn(): boolean {
    return (
      this.checklistsSelected.length > 0 ||
      this.managersSelectedItems.length > 0 ||
      this.projectsSelectedItems.length > 0 ||
      this.linkedPhonesSelectedItems.length > 0 ||
      this.estimationSelectValue !== `null` ||
      this.initialCallsValue > 0 ||
      this.minDuration.length + this.maxDuration.length > 0 ||
      (this.callDates.from?.length ?? 0 + this.callDates.to?.length ?? 0) > 0 ||
      (this.estimationDates.from?.length ??
        0 + this.estimationDates.to?.length ??
        0) > 0
    );
  }

  get managersSelectOptions(): BaseEntityModel[] {
    return this.$store.getters.getCallsManagersList;
  }

  get projectSelectOptions(): BaseEntityModel[] {
    return this.$store.getters.getCallsProjectsList;
  }

  get checklistsSelectOptions(): BaseEntityModel[] {
    const checklistsMap = getStore()
      .getters.getCallsProjectsList
      .flatMap(p => (Array.isArray(p['checklists']) ? p['checklists'] : []) as BaseEntityModel[])
      .reduce((acc, v) => {
        acc.set(v.id, v)
        return acc
      }, new Map<number, BaseEntityModel>())
    return Array.from(checklistsMap.values())
  }

  get linkedPhonesSelectOptions(): string[] {
    return this.$store.getters.getCallsLinkedPhonesList;
  }

  setInitialSelectedProject() {
    const selectedProjectsList: number[] = (
      this.$store.getters.getCallsFilterValue as CallsListFilterModel
    ).projects;
    if (selectedProjectsList.length > 0) {
      this.projectsSelectedItems = this.projectSelectOptions.filter(
        (item) => item.id == selectedProjectsList[0]
      );
    }
  }

  setInitialSelectedLinkedPhones() {
    const selectedLinkedPhones: string[] = (
      this.$store.getters.getCallsFilterValue as CallsListFilterModel
    ).linkedCalls;
    if (selectedLinkedPhones.length > 0) {
      this.linkedPhonesSelectedItems = selectedLinkedPhones;
    }
  }

  onCallsDateFilterChange(dates: DateFilterModel) {
    this.callDates = dates;
  }

  minDurationListener(value: string) {
    this.minDuration = value;
  }

  maxDurationListener(value: string) {
    this.maxDuration = value;
  }

  onEstimationDateFilterChange(dates: DateFilterModel) {
    this.estimationDates = dates;
  }

  handleCallsLinkedPhonesFilterValueChange(linkedPhones: string[]) {
    this.linkedPhonesSelectedItems = linkedPhones;
  }
}
